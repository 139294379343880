function obtenerPermisos(usuario, modulo) {
  if (usuario) {
    return usuario.rol_modulo_permisos.find(
      (c) => c.codigo_rol == usuario.codigo_rol && c.nombre_modulo_permiso == modulo
    )
  }
  return {}
}

export const permiso = {
  obtenerPermisos
}
